/**
 * Reverses an key and value of an object
 * For example
 *  { a: 1, b: 2 }
 *
 * Reversing the above object will become
 * { 1: 'a', 2: 'b' }
 */

module.exports = (original) =>
  Object.keys(original).reduce(
    (obj, key) => Object.assign({}, obj, { [original[key]]: key }),
    {}
  );
