import * as CSV from 'csv-string';

const removeNumbers = (i) => (isNumeric(i[0]) ? i.substr(1) : i);

export default function parseCsv(csv) {
  const json = CSV.parse(csv);
  const header = json[0];
  const result = json.slice(1).map((row) => {
    const obj = {};
    header.forEach((key, i) => (obj[key] = row[i]));
    return obj;
  });

  // We use numbers in the string to sort which one to show first
  let headers = header
    // Don't include columns with xxx
    .filter((i) => !i.includes('xxx'))
    // Always required
    .filter((i) => i !== 'Merk' && i !== 'Naam' && i !== 'Plaatje')
    .sort();

  // Remove the Numbers after sorting
  headers = headers.map(removeNumbers);

  let initial = 1;
  const products = result
    .map((item, index) => ({
      ...item,
      // Convert string number in EU format (for example: "1,2333") to decimal (1.2333)
      rankingxxx: parseLocalNum(item.rankingxxx || index)
    }))
    .sort((a, b) => a.rankingxxx - b.rankingxxx)
    .map((_item, index, arr) => {
      const item = {};
      Object.keys(_item).forEach(
        (key) => (item[removeNumbers(key)] = _item[key])
      );

      item.id = _item.Idxxx || _item.idxxx;
      item.image_url = _item.Plaatje || _item.plaatje;
      item.name = _item.Naam || _item.naam;
      item.brandName = _item.Merk || _item.merk;

      // Assign `rank` based on `rankingxxx` value.
      // If two items have same rankingxxx, use the same rank
      if (index === 0 || _item.rankingxxx === arr[index - 1].rankingxxx) {
        item.rank = initial;
      } else {
        item.rank = ++initial;
      }

      return item;
    });

  return { products, headers };
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// Converts a string to decimal number
function parseLocalNum(num) {
  return +num.toString().replace(',', '.');
}
