import deferify from 'lib/deferify-action';

export const FETCH_RANKING = 'FETCH_RANKING';
export const FETCH_RANKING_REQUEST = `${FETCH_RANKING}_REQUEST`;
export const FETCH_RANKING_SUCCESS = `${FETCH_RANKING}_SUCCESS`;
export const FETCH_RANKING_FAILURE = `${FETCH_RANKING}_FAILURE`;

export const FETCH_RANKINGS = 'FETCH_RANKINGS';
export const FETCH_RANKINGS_REQUEST = `${FETCH_RANKINGS}_REQUEST`;
export const FETCH_RANKINGS_SUCCESS = `${FETCH_RANKINGS}_SUCCESS`;
export const FETCH_RANKINGS_FAILURE = `${FETCH_RANKINGS}_FAILURE`;

export function fetchRanking(params) {
  return deferify({ type: FETCH_RANKING, ...params });
}

export function fetchRankings(params) {
  return deferify({ type: FETCH_RANKINGS, ...params });
}
