// https://docs.bugsnag.com/platforms/browsers/react/

import bugsnag from 'bugsnag-js';
import { BUGSNAG_API_KEY, RUNTIME_ENV } from '../config';

export default bugsnag({
  apiKey: BUGSNAG_API_KEY || 'not set',
  autoCaptureSessions: false,
  notifyReleaseStages: BUGSNAG_API_KEY ? ['production', 'staging'] : [],
  releaseStage: RUNTIME_ENV,
  collectUserIp: false // gdpr
});
