const isProdBuild = process.env.NODE_ENV === 'production';
const isDevBuild =
  process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;

if (process.env.DYNAMIC_CONFIG && typeof window !== 'undefined') {
  const cfg = window.__config__ || {};
  module.exports = {
    isProdBuild,
    isProdRuntime: cfg.RUNTIME_ENV === 'production',
    isDevBuild,
    isDevRuntime: cfg.RUNTIME_ENV === 'development' || !cfg.RUNTIME_ENV,
    isDynamicConfig: true,
    QM_API_URL: cfg.QM_API_URL,
    PRISMIC_API_URL: cfg.PRISMIC_API_URL,
    BUGSNAG_API_KEY: cfg.BUGSNAG_API_KEY,
    RUNTIME_ENV: cfg.RUNTIME_ENV,
    G_TAG_ID: cfg.G_TAG_ID,
    GA_CONVERSION_ID: cfg.GA_CONVERSION_ID,
    GA_CONVERSION_LABEL: cfg.GA_CONVERSION_LABEL,
    GOOGLE_SITE_VERIFICATION: cfg.GOOGLE_SITE_VERIFICATION,
    FB_APP_ID: cfg.FB_APP_ID,
    MAIN_WEBSITE_URL: cfg.MAIN_WEBSITE_URL,
    CANONICAL_URL: cfg.CANONICAL_URL
  };
} else {
  module.exports = {
    isProdBuild,
    isProdRuntime: isProdBuild,
    isDevBuild,
    isDevRuntime: isDevBuild,
    isDynamicConfig: process.env.DYNAMIC_CONFIG === 'true',
    QM_API_URL: process.env.QM_API_URL,
    PRISMIC_API_URL: process.env.PRISMIC_API_URL,
    BUGSNAG_API_KEY: process.env.BUGSNAG_API_KEY,
    RUNTIME_ENV: process.env.RUNTIME_ENV,
    G_TAG_ID: process.env.G_TAG_ID,
    GA_CONVERSION_ID: process.env.GA_CONVERSION_ID,
    GA_CONVERSION_LABEL: process.env.GA_CONVERSION_LABEL,
    GOOGLE_SITE_VERIFICATION: process.env.GOOGLE_SITE_VERIFICATION,
    FB_APP_ID: process.env.FB_APP_ID,
    MAIN_WEBSITE_URL: process.env.MAIN_WEBSITE_URL,
    CANONICAL_URL: process.env.CANONICAL_URL
  };
}
