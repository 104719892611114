// The keys of the below objects holds the page types in prismic and the
// values are the corresponding internationalized names

const en = {
  about: 'about',
  about_main: 'about-us',
  case_list: 'case-files',
  case: 'case',
  page: 'page',
  press: 'press',
  superlist: 'superlist'
};

const nl = {
  about: 'over',
  about_main: 'over-ons',
  case_list: 'dossiers',
  case: 'dossier',
  page: 'pagina',
  press: 'in-de-media',
  superlist: 'superlijst'
};

module.exports = {
  en,
  nl,

  // Sugar for prismic api interaction
  'en-gb': en,
  'nl-nl': nl
};
