import deferify from 'lib/deferify-action';

export const FETCH_BRAND = 'FETCH_BRAND';
export const FETCH_BRAND_REQUEST = `${FETCH_BRAND}_REQUEST`;
export const FETCH_BRAND_SUCCESS = `${FETCH_BRAND}_SUCCESS`;
export const FETCH_BRAND_FAILURE = `${FETCH_BRAND}_FAILURE`;

export const FETCH_BRAND_REPORT = 'FETCH_BRAND_REPORT';
export const FETCH_BRAND_REPORT_REQUEST = `${FETCH_BRAND_REPORT}_REQUEST`;
export const FETCH_BRAND_REPORT_SUCCESS = `${FETCH_BRAND_REPORT}_SUCCESS`;
export const FETCH_BRAND_REPORT_FAILURE = `${FETCH_BRAND_REPORT}_FAILURE`;

export function fetchBrand(params) {
  return deferify({ type: FETCH_BRAND, ...params });
}

export function fetchBrandReport(params) {
  return deferify({ type: FETCH_BRAND_REPORT, ...params });
}
