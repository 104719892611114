const cookies = require('next-cookies');

const localeMap = {
  en: 'en-gb',
  nl: 'nl-nl'
};

/**
 * Expose
 */

function locale(ctx) {
  // get the locale from cookie
  const { lang } = cookies(ctx);
  // get locale from the url
  let langFromUrl;
  if (ctx && ctx.req && ctx.req.originalUrl.startsWith('/en')) {
    langFromUrl = 'en';
  }
  // first precedence to locale from the requested url and then cookies
  // default is nl
  return langFromUrl || lang || 'nl';
}

function localeISO(ctx) {
  return localeMap[locale(ctx)];
}

module.exports = {
  locale,
  localeISO,
  localeMap
};
