import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_USAGES_REQUEST,
  FETCH_USAGES_SUCCESS
} from 'actions/categories';

const initial = {
  items: [],
  usages: [],
  loading: false
};

export default function categories(state = initial, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
    case FETCH_USAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.categories
      };
    case FETCH_USAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        usages: action.payload.usages
      };
    default:
      return state;
  }
}
