import {
  FETCH_BLOG_REQUEST,
  FETCH_IMPACTS_REQUEST,
  FETCH_CASE_FILES_REQUEST,
  FETCH_HOME_SUCCESS,
  FETCH_ABOUT_SUCCESS,
  FETCH_BLOG_SUCCESS,
  FETCH_BLOG_POST_SUCCESS,
  FETCH_FAQ_SUCCESS,
  FETCH_TEAM_SUCCESS,
  FETCH_PRESS_SUCCESS,
  FETCH_LANDING_SUCCESS,
  FETCH_PRISMIC_WIJZERS_SUCCESS,
  FETCH_PRISMIC_WIJZER_SUCCESS,
  FETCH_PAGE_REQUEST,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_FAILURE,
  FETCH_CASE_FILES_SUCCESS,
  FETCH_CASE_FILE_SUCCESS,
  FETCH_IMPACTS_SUCCESS,
  FETCH_IMPACT_SUCCESS,
  FETCH_PUBLICATIONS_SUCCESS,
  FETCH_PRODUCT_META_SUCCESS,
  FETCH_CATEGORIES_META_SUCCESS,
  FETCH_BRAND_META_SUCCESS,
  FETCH_CONTACT_SUCCESS,
  FETCH_COMPARE_SUCCESS,
  FETCH_DONATE_SUCCESS
} from 'actions/prismic';

// @todo
// These need to be revisited

const initial = {
  home: null,
  about: null,
  blog: {},
  blog_post: null,
  faq: null,
  team: null,
  press: null,
  campaign: null,
  wijzers: null,
  wijzer: null,
  pages: {},
  case_files: {},
  case_file: null,
  impacts: {},
  impact: null,
  publications: {},
  landing: null,
  products: {},
  categories: {},
  sustainability: {},
  contact: {},
  compare: {},
  donate: null
};

export default function prismic(state = initial, action) {
  switch (action.type) {
    case FETCH_BLOG_REQUEST:
      return {
        ...state,
        blog: {
          ...state.blog,
          loading: true,
          results: []
        }
      };
    case FETCH_CASE_FILES_REQUEST:
      return {
        ...state,
        case_files: {
          ...state.case_files,
          loading: true,
          results: []
        }
      };
    case FETCH_IMPACTS_REQUEST:
      return {
        ...state,
        impacts: {
          ...state.impacts,
          loading: true,
          results: []
        }
      };
    case FETCH_HOME_SUCCESS:
      return {
        ...state,
        home: action.payload
      };
    case FETCH_ABOUT_SUCCESS:
      return {
        ...state,
        about: action.payload
      };
    case FETCH_BLOG_SUCCESS:
      return {
        ...state,
        blog: action.payload
      };
    case FETCH_BLOG_POST_SUCCESS:
      return {
        ...state,
        blog_post: action.payload
      };
    case FETCH_FAQ_SUCCESS:
      return {
        ...state,
        faq: action.payload
      };
    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        team: action.payload
      };
    case FETCH_PRESS_SUCCESS:
      return {
        ...state,
        press: action.payload
      };
    case FETCH_LANDING_SUCCESS:
      return {
        ...state,
        landing: action.payload
      };
    case FETCH_PRISMIC_WIJZERS_SUCCESS:
      return {
        ...state,
        wijzers: action.payload
      };
    case FETCH_PRISMIC_WIJZER_SUCCESS:
      return {
        ...state,
        wijzer: action.payload
      };
    case FETCH_PAGE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          loading: true
        }
      };
    case FETCH_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          loading: false,
          [action.payload.uid]: action.payload
        }
      };
    case FETCH_PAGE_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          loading: false
        }
      };
    case FETCH_CASE_FILES_SUCCESS:
      return {
        ...state,
        case_files: action.payload
      };
    case FETCH_CASE_FILE_SUCCESS:
      return {
        ...state,
        case_file: action.payload
      };
    case FETCH_IMPACTS_SUCCESS:
      return {
        ...state,
        impacts: action.payload
      };
    case FETCH_IMPACT_SUCCESS:
      return {
        ...state,
        impact: action.payload
      };
    case FETCH_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        publications: action.payload
      };
    case FETCH_PRODUCT_META_SUCCESS:
      return {
        ...state,
        products: action.payload
      };
    case FETCH_BRAND_META_SUCCESS:
      return {
        ...state,
        brand: action.payload
      };
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload
      };
    case FETCH_CATEGORIES_META_SUCCESS:
      return {
        ...state,
        categories: action.payload
      };
    case FETCH_COMPARE_SUCCESS:
      return {
        ...state,
        compare: action.payload
      };
    case FETCH_DONATE_SUCCESS:
      return {
        ...state,
        donate: action.payload
      };
    default:
      return state;
  }
}
