import deferify from 'lib/deferify-action';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_REQUEST = `${FETCH_PRODUCTS}_REQUEST`;
export const FETCH_PRODUCTS_SUCCESS = `${FETCH_PRODUCTS}_SUCCESS`;
export const FETCH_PRODUCTS_FAILURE = `${FETCH_PRODUCTS}_FAILURE`;

export const FETCH_SEARCH = 'FETCH_SEARCH';
export const FETCH_SEARCH_REQUEST = `${FETCH_SEARCH}_REQUEST`;
export const FETCH_SEARCH_SUCCESS = `${FETCH_SEARCH}_SUCCESS`;
export const FETCH_SEARCH_FAILURE = `${FETCH_SEARCH}_FAILURE`;

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_REQUEST = `${FETCH_PRODUCT}_REQUEST`;
export const FETCH_PRODUCT_SUCCESS = `${FETCH_PRODUCT}_SUCCESS`;
export const FETCH_PRODUCT_FAILURE = `${FETCH_PRODUCT}_FAILURE`;

export const FETCH_ALTERNATIVES = 'FETCH_ALTERNATIVES';
export const FETCH_ALTERNATIVES_REQUEST = `${FETCH_ALTERNATIVES}_REQUEST`;
export const FETCH_ALTERNATIVES_SUCCESS = `${FETCH_ALTERNATIVES}_SUCCESS`;
export const FETCH_ALTERNATIVES_FAILURE = `${FETCH_ALTERNATIVES}_FAILURE`;

export const FETCH_SUSTAINABILITY_REPORT = 'FETCH_SUSTAINABILITY_REPORT';
export const FETCH_SUSTAINABILITY_REPORT_REQUEST = `${FETCH_SUSTAINABILITY_REPORT}_REQUEST`;
export const FETCH_SUSTAINABILITY_REPORT_SUCCESS = `${FETCH_SUSTAINABILITY_REPORT}_SUCCESS`;
export const FETCH_SUSTAINABILITY_REPORT_FAILURE = `${FETCH_SUSTAINABILITY_REPORT}_FAILURE`;

export const ADD_TO_COMPARISON_LIST = 'ADD_TO_COMPARISON_LIST';
export const REMOVE_FROM_COMPARISON_LIST = 'REMOVE_FROM_COMPARISON_LIST';
export const CLEAR_COMPARISON_LIST = 'CLEAR_COMPARISON_LIST';
export const CLEAR_COMPARISON = 'CLEAR_COMPARISON';

export function fetchProducts(params) {
  return deferify({ type: FETCH_PRODUCTS, ...params });
}

export function fetchSearch(params) {
  return deferify({ type: FETCH_SEARCH, ...params });
}

export function fetchProduct(params) {
  return deferify({ type: FETCH_PRODUCT, ...params });
}

export function fetchAlternatives(params) {
  return deferify({ type: FETCH_ALTERNATIVES, ...params });
}

export function fetchSustainabilityReport(params) {
  return deferify({ type: FETCH_SUSTAINABILITY_REPORT, ...params });
}

export function addToComparisons(item) {
  return { type: ADD_TO_COMPARISON_LIST, payload: item };
}

export function removeFromComparisons(item) {
  return { type: REMOVE_FROM_COMPARISON_LIST, payload: item };
}

export function clearComparisons() {
  return { type: CLEAR_COMPARISON_LIST };
}

export function clearCoparison() {
  return { type: CLEAR_COMPARISON };
}
