// Taken from
// https://github.com/ReactTraining/react-router/issues/1100#issuecomment-272800685

import qs from 'qs';
import { Router } from 'router';

/**
 * @param {Object} query
 */
export const addQuery = (query) => {
  const newLocation = add(query);
  Router.pushRoute(newLocation);
};

/**
 * @param {...String} names
 */
export const removeQuery = (...names) => {
  const newLocation = remove(names);
  Router.pushRoute(newLocation);
};

export function getQuery() {
  const search = window.location.search.slice(1);
  return qs.parse(search);
}

export function toQS(obj) {
  return qs.stringify(obj, { arrayFormat: 'brackets' });
}

function add(next) {
  const current = getQuery();
  return merge(current, next);
}

function remove(names) {
  const current = getQuery();
  names.forEach((q) => delete current[q]);
  return merge(current);
}

function merge(current, next = {}) {
  const query = { ...current, ...next };
  return window.location.pathname + '?' + qs.stringify(query);
}
