import {
  FETCH_BRAND_REQUEST,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_REPORT_REQUEST,
  FETCH_BRAND_REPORT_SUCCESS
} from 'actions/brands';

const initial = {
  selected: {},
  reports: {},
  loading: false
};

export default function brands(state = initial, action) {
  switch (action.type) {
    case FETCH_BRAND_REQUEST:
    case FETCH_BRAND_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: {
          ...state.selected,
          [action.payload.id]: action.payload
        }
      };
    case FETCH_BRAND_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          ...state.reports,
          [action.payload.id]: action.payload
        }
      };
    default:
      return state;
  }
}
