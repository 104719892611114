import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_SEARCH_REQUEST,
  FETCH_SEARCH_SUCCESS,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_ALTERNATIVES_REQUEST,
  FETCH_ALTERNATIVES_SUCCESS,
  FETCH_SUSTAINABILITY_REPORT_REQUEST,
  FETCH_SUSTAINABILITY_REPORT_SUCCESS,
  ADD_TO_COMPARISON_LIST,
  REMOVE_FROM_COMPARISON_LIST,
  CLEAR_COMPARISON_LIST
} from 'actions/products';
import slugify from 'lib/slugify';
import { groupBy } from 'lodash';
import { stringCompare } from 'lib/view-helpers';

const initial = {
  items: [],
  total: null,
  search: [],
  comparisons: [],
  loading: false,
  searching: false,
  selected: {},
  alternatives: {},
  sustainability_reports: {}
};

export default function products(state = initial, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        items: []
      };
    case FETCH_PRODUCT_REQUEST:
    case FETCH_ALTERNATIVES_REQUEST:
    case FETCH_SUSTAINABILITY_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        searching: true
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searching: false,
        search: action.payload.products.map(transform),
        total: action.payload.total
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.products.map(transform),
        total: action.payload.total
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: {
          ...state.selected,
          [action.product_id]: transform(action.payload)
        }
      };
    case FETCH_ALTERNATIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        alternatives: {
          ...state.alternatives,
          [action.product_id]: action.payload.products.map(transform)
        }
      };
    case FETCH_SUSTAINABILITY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        sustainability_reports: {
          ...state.sustainability_reports,
          [action.product_id]: sustainabilityReport(action.payload)
        }
      };
    case ADD_TO_COMPARISON_LIST:
      return {
        ...state,
        comparisons: state.comparisons.concat([action.payload])
      };
    case REMOVE_FROM_COMPARISON_LIST:
      return {
        ...state,
        comparisons: state.comparisons.filter(
          (i) => !stringCompare(i.id, action.payload.id)
        )
      };
    case CLEAR_COMPARISON_LIST:
      return {
        ...state,
        comparisons: []
      };
    default:
      return state;
  }
}

function transform(product) {
  product.image =
    (product.image_urls &&
      product.image_urls.length &&
      product.image_urls[0]) ||
    {};
  product.path = slugify(product.id, product.name);
  const top = product.certificates.filter((c) => c.top);
  const other = product.certificates.filter((c) => !c.top);
  const cpd = product.certificate_policy_descriptions.reduce((initial, p) => {
    initial[p.certificate_id] = p.sections;
    return initial;
  }, {});
  const certs = (c) => ({
    ...c,
    description: cpd[c.id] || (c.description && [c.description]) || []
  });
  product.certificates = {
    top: top.map(certs),
    other: other.map(certs)
  };
  product.nutrients = product.product_nutrients.reduce((obj, n) => {
    obj[n.code] = n;
    return obj;
  }, {});
  return product;
}

function sustainabilityReport(report) {
  const themes = report.hotspot_summaries
    .map((h) => h.theme)
    .filter((h) => h !== 'general');
  const getTheme = (key, theme) => {
    const arr = report[key].filter((p) => p.theme === theme);
    return arr[0] || {};
  };
  const sorter = (a, b) => a.priority - b.priority;
  const statements = report.statements.sort(sorter);

  const obj = {};
  obj.name = report.title;
  obj.source_description = report.source_description;
  obj.description = report.hotspot_intro_summary;
  obj.description_extra = report.content_description;
  obj.case_file = getTheme('fact_sheets', 'general');
  obj.scope = report.scope;
  obj.glossary = report.glossary;
  obj.supplied = report.source_type === 'supplied';
  obj.preview = report.preview;
  obj.updated_on = report.updated_on;

  obj.statements = statements.map((s, index) => {
    // add a unique index if one doesn't exist
    s.id = s.id || index + 1;

    // sort sub statements
    if (s.substatements) s.substatements = s.substatements.sort(sorter);

    // populate topic
    s.topic = report.topics.filter((t) => t.id === s.topic_id)[0];

    // include source name for certificates
    if (s.source && s.source.type === 'certificate') {
      const c = report.scope.certificates.filter(
        (c) => c.id === s.source.id
      )[0];
      if (c) s.source.name = c.name;
    }

    return s;
  });

  // general statements that doesn't belong to a particular stage in the chain
  obj.statements_general = obj.statements.filter(
    (s) =>
      s.lifecycle_stage_ids.length === 0 ||
      s.lifecycle_stage_ids.filter(
        (id) => !report.lifecycle_stages.map((l) => l.id).includes(id)
      ).length > 0
  );

  // group statements by lifecycle stages, then by source
  obj.stages = report.lifecycle_stages.map(function (l) {
    const statements = obj.statements.filter((s) =>
      s.lifecycle_stage_ids.includes(l.id)
    );

    return {
      ...l,
      sources: {
        brand: statements.filter((s) => s.source.type === 'brand'),
        certificates: Object.values(
          groupBy(
            statements.filter((s) => s.source.type === 'certificate'),
            (s) => s.source.id
          )
        ),
        statement_count: statements.length
      }
    };
  });

  obj.quotes = themes
    .map((theme) => getTheme('brand_quotes', theme))
    .filter(hasKeys)
    .concat([getTheme('brand_quotes', 'general')])
    .filter(hasKeys);

  obj.themes = themes.map((theme) => {
    const hotspot = getTheme('hotspot_summaries', theme);
    return {
      name: theme,
      description: hotspot.body,
      teaser: hotspot.teaser,
      case_file: getTheme('fact_sheets', theme),
      topics: report.topics.filter((t) => t.theme === theme)
    };
  });

  return obj;
}

function hasKeys(obj) {
  return Object.keys(obj).length > 0;
}
