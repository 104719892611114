import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import App from 'next/app';
import Head from 'next/head';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import Prismic from 'prismic-javascript';
import { locale, localeMap } from 'lib/locale-cookies';
import getPreviewRef from 'lib/get-preview-ref';
import configureStore from '../store';
import { PRISMIC_API_URL } from '../config';

class Questionmark extends App {
  static async getInitialProps({ Component, router, ctx }) {
    const app = ctx.query.app; // "fix" for mobile embed
    const urlLang = ctx.query.lang || locale(ctx) || 'nl';
    const lang = localeMap[urlLang];
    const props = { nav: {} };
    let pageProps = {};

    // @todo - make sure this is not fetched in client (ctx.isServer)
    // get nav from prismic
    const api = await Prismic.api(PRISMIC_API_URL).catch(() => {});

    if (api) props.nav = await api.getSingle('navigation', { lang });

    if (Component.getInitialProps) {
      // get prismic preview ref if available
      const prismicRef = getPreviewRef(ctx.req);
      pageProps = await Component.getInitialProps({ ...ctx, lang, prismicRef });
      const { deferred } = pageProps || {};
      // This is how we set response status code
      if (deferred && ctx.res) ctx.res.statusCode = await deferred.promise;
    }

    return { ...props, pageProps, app, lang: urlLang, url: router };
  }

  componentDidCatch(error, errorInfo) {
    // No server side reporting yet
    // This is due to the fact that we need to install two separate libraries
    // one for the client and another for the server (bugsnag-js and bugsnag)
    // There is no proper solution for universal loading or a next.js plugin
    // Keep an eye open
    if (typeof window === 'undefined') return;

    const bugsnag = require('lib/bugsnag').default;
    bugsnag.notify(error);
    super.componentDidCatch(error, errorInfo);
  }

  getChildContext() {
    return { app: this.props.app, lang: this.props.lang };
  }

  render() {
    const { Component, pageProps, store, ...rest } = this.props;
    return (
      <Provider store={store}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <Component {...pageProps} {...rest} />
      </Provider>
    );
  }
}

Questionmark.childContextTypes = {
  lang: PropTypes.oneOf(['en', 'nl']),
  app: PropTypes.string // fix for mobile embed
};

Questionmark.propTypes = {
  lang: PropTypes.string
};

export default withRedux(configureStore)(withReduxSaga(Questionmark));
