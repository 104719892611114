import deferify from 'lib/deferify-action';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_REQUEST = `${FETCH_CATEGORIES}_REQUEST`;
export const FETCH_CATEGORIES_SUCCESS = `${FETCH_CATEGORIES}_SUCCESS`;
export const FETCH_CATEGORIES_FAILURE = `${FETCH_CATEGORIES}_FAILURE`;

export const FETCH_USAGES = 'FETCH_USAGES';
export const FETCH_USAGES_REQUEST = `${FETCH_USAGES}_REQUEST`;
export const FETCH_USAGES_SUCCESS = `${FETCH_USAGES}_SUCCESS`;
export const FETCH_USAGES_FAILURE = `${FETCH_USAGES}_FAILURE`;

export function fetchCategories(params) {
  return deferify({ type: FETCH_CATEGORIES, ...params });
}

export function fetchUsages(params) {
  return deferify({ type: FETCH_USAGES, ...params });
}
