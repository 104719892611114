import deferify from 'lib/deferify-action';

export const FETCH_HOME = 'FETCH_HOME';
export const FETCH_HOME_REQUEST = `${FETCH_HOME}_REQUEST`;
export const FETCH_HOME_SUCCESS = `${FETCH_HOME}_SUCCESS`;
export const FETCH_HOME_FAILURE = `${FETCH_HOME}_FAILURE`;

export const FETCH_ABOUT = 'FETCH_ABOUT';
export const FETCH_ABOUT_REQUEST = `${FETCH_ABOUT}_REQUEST`;
export const FETCH_ABOUT_SUCCESS = `${FETCH_ABOUT}_SUCCESS`;
export const FETCH_ABOUT_FAILURE = `${FETCH_ABOUT}_FAILURE`;

export const FETCH_BLOG = 'FETCH_BLOG';
export const FETCH_BLOG_REQUEST = `${FETCH_BLOG}_REQUEST`;
export const FETCH_BLOG_SUCCESS = `${FETCH_BLOG}_SUCCESS`;
export const FETCH_BLOG_FAILURE = `${FETCH_BLOG}_FAILURE`;

export const FETCH_BLOG_POST = 'FETCH_BLOG_POST';
export const FETCH_BLOG_POST_REQUEST = `${FETCH_BLOG_POST}_REQUEST`;
export const FETCH_BLOG_POST_SUCCESS = `${FETCH_BLOG_POST}_SUCCESS`;
export const FETCH_BLOG_POST_FAILURE = `${FETCH_BLOG_POST}_FAILURE`;

export const FETCH_FAQ = 'FETCH_FAQ';
export const FETCH_FAQ_REQUEST = `${FETCH_FAQ}_REQUEST`;
export const FETCH_FAQ_SUCCESS = `${FETCH_FAQ}_SUCCESS`;
export const FETCH_FAQ_FAILURE = `${FETCH_FAQ}_FAILURE`;

export const FETCH_TEAM = 'FETCH_TEAM';
export const FETCH_TEAM_REQUEST = `${FETCH_TEAM}_REQUEST`;
export const FETCH_TEAM_SUCCESS = `${FETCH_TEAM}_SUCCESS`;
export const FETCH_TEAM_FAILURE = `${FETCH_TEAM}_FAILURE`;

export const FETCH_PRESS = 'FETCH_PRESS';
export const FETCH_PRESS_REQUEST = `${FETCH_PRESS}_REQUEST`;
export const FETCH_PRESS_SUCCESS = `${FETCH_PRESS}_SUCCESS`;
export const FETCH_PRESS_FAILURE = `${FETCH_PRESS}_FAILURE`;

export const FETCH_LANDING = 'FETCH_LANDING';
export const FETCH_LANDING_REQUEST = `${FETCH_LANDING}_REQUEST`;
export const FETCH_LANDING_SUCCESS = `${FETCH_LANDING}_SUCCESS`;
export const FETCH_LANDING_FAILURE = `${FETCH_LANDING}_FAILURE`;

export const FETCH_PRISMIC_WIJZERS = 'FETCH_PRISMIC_WIJZERS';
export const FETCH_PRISMIC_WIJZERS_REQUEST = `${FETCH_PRISMIC_WIJZERS}_REQUEST`;
export const FETCH_PRISMIC_WIJZERS_SUCCESS = `${FETCH_PRISMIC_WIJZERS}_SUCCESS`;
export const FETCH_PRISMIC_WIJZERS_FAILURE = `${FETCH_PRISMIC_WIJZERS}_FAILURE`;

export const FETCH_PRISMIC_WIJZER = 'FETCH_PRISMIC_WIJZER';
export const FETCH_PRISMIC_WIJZER_REQUEST = `${FETCH_PRISMIC_WIJZER}_REQUEST`;
export const FETCH_PRISMIC_WIJZER_SUCCESS = `${FETCH_PRISMIC_WIJZER}_SUCCESS`;
export const FETCH_PRISMIC_WIJZER_FAILURE = `${FETCH_PRISMIC_WIJZER}_FAILURE`;

export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_REQUEST = `${FETCH_PAGE}_REQUEST`;
export const FETCH_PAGE_SUCCESS = `${FETCH_PAGE}_SUCCESS`;
export const FETCH_PAGE_FAILURE = `${FETCH_PAGE}_FAILURE`;

export const FETCH_CASE_FILES = 'FETCH_CASE_FILES';
export const FETCH_CASE_FILES_REQUEST = `${FETCH_CASE_FILES}_REQUEST`;
export const FETCH_CASE_FILES_SUCCESS = `${FETCH_CASE_FILES}_SUCCESS`;
export const FETCH_CASE_FILES_FAILURE = `${FETCH_CASE_FILES}_FAILURE`;

export const FETCH_CASE_FILE = 'FETCH_CASE_FILE';
export const FETCH_CASE_FILE_REQUEST = `${FETCH_CASE_FILE}_REQUEST`;
export const FETCH_CASE_FILE_SUCCESS = `${FETCH_CASE_FILE}_SUCCESS`;
export const FETCH_CASE_FILE_FAILURE = `${FETCH_CASE_FILE}_FAILURE`;

export const FETCH_IMPACTS = 'FETCH_IMPACTS';
export const FETCH_IMPACTS_REQUEST = `${FETCH_IMPACTS}_REQUEST`;
export const FETCH_IMPACTS_SUCCESS = `${FETCH_IMPACTS}_SUCCESS`;
export const FETCH_IMPACTS_FAILURE = `${FETCH_IMPACTS}_FAILURE`;

export const FETCH_IMPACT = 'FETCH_IMPACT';
export const FETCH_IMPACT_REQUEST = `${FETCH_IMPACT}_REQUEST`;
export const FETCH_IMPACT_SUCCESS = `${FETCH_IMPACT}_SUCCESS`;
export const FETCH_IMPACT_FAILURE = `${FETCH_IMPACT}_FAILURE`;

export const FETCH_PUBLICATIONS = 'FETCH_PUBLICATIONS';
export const FETCH_PUBLICATIONS_REQUEST = `${FETCH_PUBLICATIONS}_REQUEST`;
export const FETCH_PUBLICATIONS_SUCCESS = `${FETCH_PUBLICATIONS}_SUCCESS`;
export const FETCH_PUBLICATIONS_FAILURE = `${FETCH_PUBLICATIONS}_FAILURE`;

export const FETCH_PRODUCT_META = 'FETCH_PRODUCT_META';
export const FETCH_PRODUCT_META_REQUEST = `${FETCH_PRODUCT_META}_REQUEST`;
export const FETCH_PRODUCT_META_SUCCESS = `${FETCH_PRODUCT_META}_SUCCESS`;
export const FETCH_PRODUCT_META_FAILURE = `${FETCH_PRODUCT_META}_FAILURE`;

export const FETCH_BRAND_META = 'FETCH_BRAND_META';
export const FETCH_BRAND_META_REQUEST = `${FETCH_BRAND_META}_REQUEST`;
export const FETCH_BRAND_META_SUCCESS = `${FETCH_BRAND_META}_SUCCESS`;
export const FETCH_BRAND_META_FAILURE = `${FETCH_BRAND_META}_FAILURE`;

export const FETCH_CONTACT = 'FETCH_CONTACT';
export const FETCH_CONTACT_REQUEST = `${FETCH_CONTACT}_REQUEST`;
export const FETCH_CONTACT_SUCCESS = `${FETCH_CONTACT}_SUCCESS`;
export const FETCH_CONTACT_FAILURE = `${FETCH_CONTACT}_FAILURE`;

export const FETCH_CATEGORIES_META = 'FETCH_CATEGORIES_META';
export const FETCH_CATEGORIES_META_REQUEST = `${FETCH_CATEGORIES_META}_REQUEST`;
export const FETCH_CATEGORIES_META_SUCCESS = `${FETCH_CATEGORIES_META}_SUCCESS`;
export const FETCH_CATEGORIES_META_FAILURE = `${FETCH_CATEGORIES_META}_FAILURE`;

export const FETCH_COMPARE = 'FETCH_COMPARE';
export const FETCH_COMPARE_REQUEST = `${FETCH_COMPARE}_REQUEST`;
export const FETCH_COMPARE_SUCCESS = `${FETCH_COMPARE}_SUCCESS`;
export const FETCH_COMPARE_FAILURE = `${FETCH_COMPARE}_FAILURE`;

export const FETCH_DONATE = 'FETCH_DONATE';
export const FETCH_DONATE_REQUEST = `${FETCH_DONATE}_REQUEST`;
export const FETCH_DONATE_SUCCESS = `${FETCH_DONATE}_SUCCESS`;
export const FETCH_DONATE_FAILURE = `${FETCH_DONATE}_FAILURE`;

export function fetchHome(params) {
  return deferify({ type: FETCH_HOME, ...params });
}

export function fetchAbout(params) {
  return deferify({ type: FETCH_ABOUT, ...params });
}

export function fetchBlog(params) {
  return deferify({ type: FETCH_BLOG, ...params });
}

export function fetchBlogPost(params) {
  return deferify({ type: FETCH_BLOG_POST, ...params });
}

export function fetchFaq(params) {
  return deferify({ type: FETCH_FAQ, ...params });
}

export function fetchTeam(params) {
  return deferify({ type: FETCH_TEAM, ...params });
}

export function fetchPress(params) {
  return deferify({ type: FETCH_PRESS, ...params });
}

export function fetchLanding(params) {
  return deferify({ type: FETCH_LANDING, ...params });
}

export function fetchPrismicWijzers(params) {
  return deferify({ type: FETCH_PRISMIC_WIJZERS, ...params });
}

export function fetchPrismicWijzer(params) {
  return deferify({ type: FETCH_PRISMIC_WIJZER, ...params });
}

export function fetchPage(params) {
  return deferify({ type: FETCH_PAGE, ...params });
}

export function fetchCaseFiles(params) {
  return deferify({ type: FETCH_CASE_FILES, ...params });
}

export function fetchCaseFile(params) {
  return deferify({ type: FETCH_CASE_FILE, ...params });
}

export function fetchImpacts(params) {
  return deferify({ type: FETCH_IMPACTS, ...params });
}

export function fetchImpact(params) {
  return deferify({ type: FETCH_IMPACT, ...params });
}

export function fetchPublications(params) {
  return deferify({ type: FETCH_PUBLICATIONS, ...params });
}

export function fetchContact(params) {
  return deferify({ type: FETCH_CONTACT, ...params });
}

export function fetchCompare(params) {
  return deferify({ type: FETCH_COMPARE, ...params });
}

export function fetchDonate(params) {
  return deferify({ type: FETCH_DONATE, ...params });
}
