const I18n = require('i18n-js');
const en = require('./en');
const nl = require('./nl');
const { locale } = require('../lib/locale-cookies');
const translate = I18n.t;

I18n.fallbacks = true;
I18n.translations = { en, nl };
I18n.missingTranslationPrefix = 'EE: ';
I18n.missingBehaviour = 'guess';
I18n.locale = 'nl';

I18n.currentLocale = function() {
  return typeof window !== 'undefined' ? locale({}) : I18n.locale;
};

I18n.t = function(key, opts = {}) {
  const options =
    typeof window !== 'undefined'
      ? Object.assign({ locale: locale({}) }, opts)
      : opts;
  return translate.call(I18n, key, options);
};

I18n.sectionName = function(section) {
  switch(section) {
    case 'ranking':
      return I18n.t('SECTION_RANKING', { count: 4 });
    case 'superlist':
    case 'superlijst':
      return I18n.t('SECTION_SUPERLIST');
    case 'product-checker':
      return I18n.t('SECTION_PRODUCT_CHECKER');
    case 'qm':
      return null;
  }
};

module.exports = I18n;
