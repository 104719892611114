import {
  FETCH_RETAILERS_REQUEST,
  FETCH_RETAILERS_SUCCESS
} from 'actions/retailers';

const initial = {
  items: [],
  total: null,
  loading: false
};

export default function RETAILERS(state = initial, action) {
  switch (action.type) {
    case FETCH_RETAILERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_RETAILERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.retailers.map(transform),
        total: action.payload.total
      };
    default:
      return state;
  }
}

function transform({ name, ...rest }) {
  return { name: name.replace(/\s+webshop$/, ''), ...rest };
}
