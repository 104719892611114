import deferify from 'lib/deferify-action';

export const FETCH_RETAILERS = 'FETCH_RETAILERS';
export const FETCH_RETAILERS_REQUEST = `${FETCH_RETAILERS}_REQUEST`;
export const FETCH_RETAILERS_SUCCESS = `${FETCH_RETAILERS}_SUCCESS`;
export const FETCH_RETAILERS_FAILURE = `${FETCH_RETAILERS}_FAILURE`;

export function fetchRetailers(params) {
  return deferify({
    type: FETCH_RETAILERS,
    ...params,
    priority: (params || {}).priority || 2
  });
}
