module.exports = {
  LANG_EN: 'English',
  LANG_SHORT_EN: 'EN',
  LANG_NL: 'Nederlands',
  LANG_SHORT_NL: 'NL',
  AND: 'en',
  ABOUT: 'Over',
  VISION: 'Visie',
  VACANCIES: 'Vacatures',
  TEAM: 'Team',
  BLOG: 'Blog',
  COMMUNITY: 'Community',
  DEVELOPERS: 'Developers',
  PARTNERS: 'Partners',
  BOARD: 'Board',
  BOARD_ADVISORS: 'Raad van Advies',
  PRESS: 'Pers',
  IN_THE_MEDIA: 'In de media',
  HISTORY: 'Geschiedenis',
  DONATE: 'Doneer',
  JOIN: 'Doe mee',
  BROWSE: 'Doorzoek alles',
  READ_MORE: 'Lees meer',
  NEWS: 'Nieuws',
  CASE_FILES: 'Dossiers',
  BACK_TO_LIST: 'Naar lijst',
  ERROR_404: 'We kunnen de opgevraagde pagina niet vinden.',
  ERROR_GENERIC: 'Oeps, er ging iets mis!',
  CONTACT: 'Contact',
  CATEGORIES: 'Categorieën',
  SUB_CATEGORIES: 'Subcategorieën',
  NUTRIENTS: 'Voedingswaarden',
  PRODUCER: 'Producent',
  PRODUCERS: 'Producenten',
  SUPERMARKET: 'Supermarkten',
  INGREDIENTS: 'Ingrediënten',
  REFRIGERATION_TYPE: 'Koeling',
  PACKAGING: 'Verpakking',
  CERTIFICATE: 'Keurmerk',
  CERTIFICATES: 'Keurmerken',
  ALTERNATIVES: 'Alternatieven',
  BACK: 'Terug',

  HEALTH_A: 'Ja',
  HEALTH_B1: 'Redelijk',
  HEALTH_B2: 'Matig',
  HEALTH_C1: 'Zeer matig',
  HEALTH_C2: 'Minst',

  HEALTHINESS_SCORE_A: 'valt in de Schijf van Vijf en draagt bij aan een <b>gezonde dagelijkse voeding</b>',
  HEALTHINESS_SCORE_B1: 'kan de <b>dagelijkse uitzondering</b> zijn op een verder gezond voedingspatroon',
  HEALTHINESS_SCORE_B2: 'kan de <b>dagelijkse uitzondering</b> zijn op een verder gezond voedingspatroon',
  HEALTHINESS_SCORE_C1: 'kan de <b>wekelijkse uitzondering</b> zijn op een verder gezond voedingspatroon',
  HEALTHINESS_SCORE_C2: 'kan de <b>wekelijkse uitzondering</b> zijn op een verder gezond voedingspatroon',

  HEALTHINESS_OF: 'Dit product %{score}.',

  THEME_ENVIRONMENT: 'Milieu',
  THEME_SOCIAL: 'Mensenrechten',
  THEME_ANIMALS: 'Dierenwelzijn',
  THEME_GENERAL: 'Algemeen',
  THEME_WARNING_UNFROZEN: 'Let op',

  THEME_SHORT_ENVIRONMENT: 'Milieu',
  THEME_SHORT_SOCIAL: 'Mens',
  THEME_SHORT_ANIMALS: 'Dier',
  THEME_SHORT_GENERAL: 'Algemeen',
  THEME_SHORT_WARNING_UNFROZEN: 'Let op',

  HEALTHY: 'Gezond',
  HEALTH: 'Gezond',
  SUSTAINABILITY: 'Duurzaam',
  SUSTAINABLE: 'Duurzaam',

  EMAIL_ADDRESS: 'E-mailadres',
  SUBSCRIBE: 'Schrijf je in',
  THANK_YOU: 'Bedankt',
  SHARE: 'Deel',

  PRODUCT_CHECKER_MISSION: 'Duurzaam en gezond eten?',
  PRODUCT_CHECKER_CAPTION: 'Wij helpen je kiezen en vergelijken in de supermarkt',
  SEARCH: 'Zoek een merk of product',
  NO_SEARCH_RESULTS: 'Geen zoekresultaten',
  SHOW_ALL_N_RESULTS: {
    one: 'Bekijk één resultaat',
    other: 'Bekijk alle %{count} resultaten'
  },
  SEARCH_RESULTS_FOR: 'Zoekresultaten voor',
  BEST_MATCH: 'Beste resultaat',
  MOST_SUSTAINABLE: 'Topkeurmerken', // temporary solution until bdk is released
  LEAST_SUSTAINABLE: 'Minst duurzaam',
  MOST_HEALTHY: 'Gezondst',
  LEAST_HEALTHY: 'Minst gezond',
  CLEAR: 'Wissen',
  SORT_ON: 'Sorteer op',

  APP_INDEX_TAGLINE:
    'Scan of zoek een product om te zien hoe gezond en duurzaam het is.',

  BROWSE_CATEGORIES: 'Zoek een product per categorie',
  WIJZER: 'Productwijzers',
  CLARIFICATION: 'Toelichting',
  SHOW: 'Bekijk',
  SHOW_ALL: 'Bekijk alles',
  BEST: 'Bovenaan',
  WORST: 'Onderaan',
  WIJZER_SHARE_TITLE: 'Bedrijf of pers en meer weten?',
  EXPAND: 'Toon alles',
  SHOW_LESS: 'Toon minder',
  COLLAPSE: 'Inklappen',
  FAQS: 'Veelgestelde vragen',
  FOLLOW_US: 'Volg ons',
  IMPACT: 'Impact',
  PAGE_NOT_FOUND: 'Pagina niet gevonden',
  OTHER_CERTIFICATES: "Overige keurmerken en logo's",
  MSG_NO_SCORE_HEALTH: 'Voor dit product kan geen gezondheidsscore berekend worden.',
  TOP_CERTIFICATES: 'Topkeurmerken',
  SOURCE: 'Bron',
  CTA_SUBSCRIBE_TITLE: 'Schrijf je in voor onze nieuwsbrief',
  CTA_LINK_TITLE: 'Inschrijven',
  DIRECTLY_TO_WIJZER: 'Naar wijzer',
  PREVIOUS: 'Vorige',
  NEXT: 'Volgende',
  BRAND: 'Merk',
  NEW: 'Nieuw',
  FILTER_ON: 'Filter op',
  FILTER_SHOW: 'Toon',
  ALL: 'Alles',
  SUSTAINABLE_ALTERNATIVES: 'Duurzame alternatieven',

  PAGE: 'Pagina',
  RENEWED: 'Vernieuwd',
  WIJZER_ISSUES: 'Deze issues zijn relevant',
  REACTIONS_FROM_BRANDS: 'Reacties van merken',
  REACTIONS_PRODUCERS: 'Reacties van producenten',
  WIJZER_GOAL:
    'Questionmark onderzoekt een productgroep op gezond- en duurzaamheid',
  WIJZER_RESPONSES_1: 'Deze',
  WIJZER_RESPONSES_2: 'producenten reageerden op de wijzer',

  NOTICE_PILOT: 'Deze pagina en alle informatie zijn onderdeel van een proefproject.',
  NOTICE_PREVIEW: 'Deze pagina en alle informatie zijn niet gepubliceerd en alleen zichtbaar via de beveiligde link.',
  NOTICE_CONTACT: 'Voor opmerkingen en vragen kunt u mailen naar',
  HOW_SUSTAINABLE_IS: 'Hoe duurzaam is',
  HOW_SUSTAINABLE_IS_SUBJECT: 'Hoe duurzaam is %{subject}?',
  BACK_TO_PRODUCT: 'Terug naar het product',
  THIS_PRODUCT: 'dit product',
  BRAND_IS_TRANSPARENT_QM: 'Dit merk is transparant via Questionmark',
  BRAND_X_IS_TRANSPARENT_QM: '%{brand} is transparant via Questionmark',
  BRAND_X_IS_TRANSPARENT_ABOUT: '%{brand} publiceert over',
  BRAND_TRANSPARENT_ABOUT_A:
    '%{brand} is transparant over hun maatregelen voor',
  BRAND_TRANSPARENT_ABOUT_B:
    ', zij geven daarin inzicht met behulp van internationaal erkende vragenlijsten.',
  BRAND_TRANSPARENT_READ_MORE: 'Lees wat ze hierover zeggen',
  NO_STATEMENTS: 'Geen informatie over inspanningen in deze ketensap bekend.',
  NO_RELEVANT_STATEMENTS:
    'Voor dit product liggen de belangrijkste aandachtsgebieden niet in deze ketenstap.',
  MORE_INFO_CASE_FILE: 'Voor meer informatie zie de',
  CASE_FILE_OF: 'dossierpagina van %{category}',
  THE_CHAIN: 'Productieketen van %{brand}',
  MEASURES_OF_BRAND: 'Maatregelen door %{brand}',
  CHAIN_HELP:
    'Voordat je een product in handen hebt, heeft het een hele reis afgelegd door de productieketen. Hier zie je de belangrijkste ketenstappen, met bij iedere stap wat %{brand} zelf aangeeft te doen om mogelijke problemen aan te pakken. Maatregelen die vereist zijn om een (top)keurmerk te verkrijgen, kun je zien door het filter te gebruiken. Je kunt ook bekijken wat een merk extra doet, door te filteren op \'eigen maatregelen\'.',
  CHAIN_WIDE_MEASURES: 'Ketenbrede maatregelen',
  SELECT_ALL_MEASURES: 'Wis filters om maatregelen in deze ketenstap te zien',
  BRAND_QUOTE_INTRO: '%{brand} over hun duurzaamheid:',
  AREAS_OF_CONCERN_GENERAL: 'Algemene aandachtsgebieden',
  AREAS_OF_CONCERN_OF: 'Aandachtsgebieden bij %{category}',
  FILTER_THEME_DESC:
    'Maatregelen op het gebied van %{theme}, zowel keurmerk eisen als eigen maatregelen',
  FILTER_CERT_DESC: 'Minimale eisen gesteld door het keurmerk %{name}',
  OWN_MEASURES: 'Eigen maatregelen',
  OWN_MEASURES_DESC: 'Maatregelen die %{brand} neemt naast of in plaats van keurmerkeisen',
  CERTIFICATE_MEASURES: 'Keurmerk %{certificate}',
  CERTIFICATE_MEASURES_MULTIPLE: 'Keurmerkmaatregelen',
  READ_MORE_ABOUT: 'Lees meer over',
  MORE_ABOUT_BRAND: 'Meer over %{brand}',
  READ_MORE_ABOUT_BRAND_DESC:
    'Lees meer over de duurzaamheids- en gezondheidsambities van %{brand}',
  THEMES: 'Thema\'s',
  CERTIFICATES_AND_MEASURES: 'Keurmerken en eigen maatregelen',

  BRAND_PAGE_INTRO:
    'Op deze pagina lees je meer over de ambitie, uitdagingen en prestaties op het gebied van duurzaamheid en gezondheid van %{brand}. De informatie op deze pagina komt van %{brand}.',
  VIEW_BRAND_PRODUCTS: {
    one: 'Bekijk het ene product van %{brand} bekend bij Questionmark',
    other: 'Bekijk de %{count} producten van %{brand} bekend bij Questionmark'
  },
  SUSTAINABILITY_POLICIES: 'Duurzaamheidsbeleid',
  SUSTAINABILITY_POLICIES_INTRO: 'In het huidige beleid van %{brand} zijn dit speerpunten op het gebied van duurzaamheid:',
  SUSTAINABILITY_AMBITIONS: 'Duurzaamheidsambities',
  SUSTAINABILITY_AMBITIONS_INTRO: 'De komende jaren werkt %{brand} op deze gebieden aan duurzaamheid:',
  HEALTH_POLICIES: 'Gezondheidsbeleid',
  HEALTH_POLICIES_INTRO: 'In het huidige beleid van %{brand} zijn dit speerpunten op het gebied van gezondheid:',
  HEALTH_AMBITIONS: 'Gezondheidsambities',
  HEALTH_AMBITIONS_INTRO: 'De komende jaren werkt %{brand} op deze gebieden aan gezondheid:',
  HEALTH_SCORES: 'Gezondheidsscores',
  TOP_CERTIFICATE_PRODUCT_COUNT: {
    one: '1 product met het keurmerk %{certificate}',
    other: '%{count} producten met het keurmerk %{certificate}'
  },
  NO_TOP_CERTIFICATES_ALL_PRODUCTS: '%{brand} werkt niet met <a href="/dossier/topkeurmerken-op-een-rij">topkeurmerken</a>.',
  NO_TOP_CERTIFICATES_PRODUCT_COUNT: {
    one: 'Bij één product werkt %{brand} niet met een <a href="/dossier/topkeurmerken-op-een-rij">topkeurmerk</a>.',
    other: 'Bij %{count} producten werkt %{brand} niet met een <a href="/dossier/topkeurmerken-op-een-rij">topkeurmerk</a>.'
  },

  PRODUCTS_IN_SCHIJF: {
    one: 'Eén van %{total} producten is gezond volgens de <a href="/schijf-van-vijf">schijf van vijf</a>',
    other: '%{count} van %{total} producten zijn gezond volgens de <a href="/schijf-van-vijf">schijf van vijf</a>'
  },
  PRODUCTS_IN_SCHIJF_ALL: {
    one: 'Het product is gezond volgens de <a href="/schijf-van-vijf">schijf van vijf</a>',
    other: 'Alle producten zijn gezond volgens de <a href="/schijf-van-vijf">schijf van vijf</a>'
  },
  PRODUCTS_IN_SCHIJF_ALL_X: {
    one: 'Eén product is gezond volgens de <a href="/schijf-van-vijf">schijf van vijf</a>',
    other: 'Alle %{count} producten zijn gezond volgens de <a href="/schijf-van-vijf">schijf van vijf</a>'
  },
  PRODUCTS_OUTSIDE_SCHIJF: {
    one: 'Eén van %{total} producten valt in de categorie %{score} gezond',
    other: '%{count} van %{total} producten vallen in de categorie %{score} gezond'
  },
  PRODUCTS_OUTSIDE_SCHIJF_ALL: {
    one: 'Het product valt in de categorie %{score} gezond',
    other: 'Alle producten vallen in de categorie %{score} gezond'
  },
  PRODUCTS_OUTSIDE_SCHIJF_ALL_X: {
    one: 'Eén product valt in de categorie %{score} gezond',
    other: 'Alle %{count} producten vallen in de categorie %{score} gezond'
  },
  PRODUCTS_WITHOUT_SCORE: {
    one: '(en één product zonder beoordeling)',
    other: '(en %{count} producten zonder beoordeling)'
  },

  INITIATIVES: '%{brand} doet mee aan…',
  INITIATIVE_READ_MORE: 'Lees meer over de afspraken',
  SEND_AN_EMAIL: 'Stuur een email',
  WEBSITE: 'Website',
  CSR_REPORT: 'MVO jaarverslag',
  INFO_PROVIDED_ON: 'Informatie verstrekt op %{date}',
  INFO_GATHERED_ON: 'Gebaseerd op publieke informatie van %{date}',
  VIEW_MEASURES: 'Bekijk de maatregelen',
  PRODUCT: 'Product',
  HEALTH: 'Gezondheid',
  SUSTAINABILITY: 'Duurzaamheid',
  SUSTAINABILITY_EXPLANATION: 'Hieronder staat per onderwerp of het merk veel of weinig openheid geeft. Waar mogelijk hebben we ook de inspanningen en resultaten meegenomen.',
  IMPORTANT_FOR_X: 'Belangrijk bij',
  MORE_ABOUT_HEALTH: 'Meer over gezondheid van dit product',
  MORE_ABOUT_SUSTAINABILITY: 'Meer over de duurzaamheid van dit product',
  MORE_ABOUT_RANKING: 'Meer over de productwijzer',
  FRONT_RUNNERS: 'Voorlopers',
  MIDDLE_RUNNERS: 'Middenmoot',
  BACK_RUNNERS: 'Achterblijvers',
  FRONT_RUNNER: 'Voorloper',
  MIDDLE_RUNNER: 'Middenmoot',
  BACK_RUNNER: 'Achterblijver',
  VIEW_MIDDLE_RUNNERS: 'Bekijk middenmoot',
  CLOSE_MIDDLE_RUNNERS: 'Sluit middenmoot',
  WHAT_DO_WE_WEIGH: 'Wat wegen we mee?',
  BEST_IN_SUSTAINABILITY: 'Beste score duurzaamheid',
  BEST_IN_HEALTH: 'Beste score gezondheid',
  NONE: 'Geen',
  NUTRIENT_SALT: 'Zout',
  NUTRIENT_SUGAR: 'Suiker',
  NUTRIENT_FAT_SATURATED: 'Verzadigd vet',
  NUTRIENT_ENERGY: 'Energie',
  NUTRIENT_FIBER: 'Vezels',
  NUTRIENT_CARBOHYDRATES: 'Koolhydraten / Glucides',
  NUTRIENT_CALCIUM: 'Calcium',
  NUTRIENT_PROTEIN: 'Eiwitten',
  NUTRIENT_IRON: 'IJzer',
  NUTRIENT_ENERGY_THERMAL: 'Energie (kcal)',
  NUTRIENT_B12: 'Vitamine B12',

  COMPARE_PRODUCTS: 'Boodschappenvergelijker',
  COMPARE: 'Vergelijk',
  SELECT_PRODUCTS_TO_COMPARE: 'Selecteer producten om te vergelijken',
  READ_LESS: 'Lees minder',
  CHOOSE_A_PRODUCT: 'Kies een product',
  CHOOSE_ANOTHER_PRODUCT: 'Kies een ander product',
  CANCEL: 'Sluit',
  SELECT_ONE_MORE: 'Selecteer nog een product',
  CHANGE_PRODUCT: 'Wijzig product',
  COMPARE_LIMIT_PRODUCTS: 'Je kunt maximaal twee producten selecteren',
  COPY: 'Kopiëren',
  COPIED: 'Gekopieerd',
  COMPARE_NOW: 'Vergelijk nu',
  ADD_PRODUCT: 'Voeg product toe',
  PRODUCT_RANKING_INFO: '%{name} staat op de %{position}e plaats in de %{ranking_name}.',
  COMPARE_PRODUCT_ON: 'Vergelijk %{name} met een ander product naar keuze op gezondheid en duurzaamheid.',
  TRANSPARENCY_INFO: 'Transparant over %{count} maatregelen',
  SEE_THE_RANKING: 'Bekijk de wijzer',
  PRODUCT_RANKING: 'Productwijzer',
  SUBSCRIBE_RANKING_DESCRIPTION: 'Schrijf je in',
  WANT_TO_KNOW_MORE: 'Meer weten',
  CONTACT_US: 'Neem contact met ons op',
  BEST_SCORE: 'Beste score',
  ALL_RETAILERS: 'Alle supermarkten',

  SLOGAN_SUPERLIST: 'Hoe bepalen supermarkten ons voedselsystem?',
  SLOGAN_RANKING: 'Vergelijk kaas, tomatensoep en %{count} andere productgroepen.',
  SLOGAN_PRODUCT_CHECKER: 'Zoek een product en check hoe duurzaam en gezond het is.',

  COUNT_PRODUCTS: {
    one: 'één product',
    other: '%{display} producten'
  },
  COUNT_RANKINGS: {
    one: 'één productwijzer',
    other: '%{display} productwijzers',
  },
  COUNT_RETAILERS: {
    one: 'één supermarktformule',
    other: '%{display} supermarktformules'
  },
  COUNT_VISITORS: {
    one: 'één bezoeker',
    other: '%{display} bezoekers'
  },
  COUNT_SCANNED: {
    one: 'één product opgezocht',
    other: '%{display} producten opgezocht'
  },
  PER_MONTH: 'per maand',

  QUESTIONMARK: 'Questionmark',
  SECTION_SUPERLIST: 'Superlijst',
  SECTION_RANKING: {
    one: 'Productwijzer',
    other: 'Productwijzers'
  },
  SECTION_PRODUCT_CHECKER: 'Productchecker',

  EXPECTED: 'Verwacht',
  PUBLICATIONS: 'Publicaties',
  AND_OTHER_PUBLICATIONS: {
    one: '+ één andere publicatie',
    other: '+ %{count} andere publicaties'
  },
  DOWNLOAD_PUBLICATION: 'Download publicatie',

  DONATION_ERROR: 'Sorry, er ging iets mis bij het doneren.',
  RECEIVE_NEWSLETTER: 'Ontvang onze nieuwsbrief',
  DONATION_AMOUNT: 'Bedrag',
  DONATE: 'Doneer',
  SIDE_NAV_TITLE: 'Gerelateerde onderwerpen',
  DISCOVER: 'Ontdek',
};
