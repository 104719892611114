import slugify from 'lib/slugify';
import {
  FETCH_RANKING_REQUEST,
  FETCH_RANKING_SUCCESS,
  FETCH_RANKINGS_REQUEST,
  FETCH_RANKINGS_SUCCESS
} from 'actions/rankings';

const initial = {
  item: null,
  items: {},
  loading: false,
  total: null
};

export default function rankings(state = initial, action) {
  switch (action.type) {
    case FETCH_RANKING_REQUEST:
    case FETCH_RANKINGS_REQUEST:
      return { ...state, loading: true };
    case FETCH_RANKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.rankings.map(transform),
        total: action.payload.total
      };
    case FETCH_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        item: transform(action.payload)
      };
    default:
      return state;
  }
}

function transform({
  cover_image_url,
  sharing_image_url,
  reactions,
  renews,
  weights,
  updated_on,
  ...rest
}) {
  return {
    ...rest,
    updated_on: new Date(updated_on),
    renews: !!renews && transform(renews),
    cover_image_url: cover_image_url || {},
    sharing_image_url: sharing_image_url || {},
    url: `/qm-wijzer/${slugify(rest.id, rest.title)}`,
    quotes: reactions,
    weights: (weights || []).map(({ value, ...rest }) => ({
      percent: 100 * value,
      ...rest
    }))
  };
}
