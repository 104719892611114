exports.HEALTH_SCORES = ['A', 'B1', 'B2', 'C1', 'C2'];
exports.SUSTAINABILITY_THEMES = ['environment', 'social', 'animals', 'general']; // in order
exports.PAGE_SIZE = 10;
exports.MAXAGE_IN_DAYS = 4 * 4 * 7; // 4 x 4 weeks
exports.PRODUCTS_SORT_OPTIONS = {
  best_match: null,
  most_sustainable: 'top_certificates_count desc',
  // least_sustainable: 'top_certificates_count',
  most_healthy: 'personal_health_score',
  least_healthy: 'personal_health_score desc'
};
