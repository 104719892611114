const routes = (module.exports = require('next-routes-extended')());

const arr = [
  ['/:lang(en)', 'index', 'index'],
  ['/:lang(en)/about-us', 'about', 'about'],
  ['/:lang(en)/blog', 'blog', 'blog'],
  ['/:lang(en)/blog/:slug', 'blog-post', 'blog-post'],
  ['/:lang(en)/faq', 'faq', 'faq'],
  ['/:lang(en)/contact', 'contact', 'contact'],
  ['/:lang(en)/press', 'press', 'press'],
  ['/:lang(en)/team', 'team', 'team'],
  ['/:lang(en)/qm-wijzer', 'qm-wijzer', 'qm-wijzer'],
  ['/:lang(en)/qm-wijzer/:slug', 'qm-wijzer-detail', 'qm-wijzer-detail'],
  ['/:lang(en)/case-files', 'case-files', 'case-files'],
  ['/:lang(en)/case/:slug', 'case', 'case'],
  ['/:lang(en)/impact', 'impacts', 'impacts'],
  ['/:lang(en)/impact/:slug', 'impact', 'impact'],
  ['/:lang(en)/product-checker', 'product-index'],
  ['/:lang(en)/:type/:slug', 'page'],
  ['/:lang(en)/:slug', 'landing', 'landing'],

  ['/', 'index', 'index'],
  ['/index-app', 'index-app', 'index-app'],
  ['/over-ons', 'about', 'about'], // nl
  ['/blog', 'blog', 'blog'],
  ['/blog/:slug', 'blog-post', 'blog-post'],
  ['/faq', 'faq', 'faq'],
  ['/contact', 'contact', 'contact'],
  ['/in-de-media', 'press', 'press'], // nl
  ['/team', 'team', 'team'],
  ['/qm-wijzer', 'qm-wijzer', 'qm-wijzer'],
  ['/qm-wijzer/:slug', 'qm-wijzer-detail', 'qm-wijzer-detail'],
  ['/dossiers', 'case-files', 'case-files'], // nl
  ['/dossier/:slug', 'case', 'case'], // nl
  ['/impact', 'impacts', 'impacts'],
  ['/impact/:slug', 'impact', 'impact'],
  ['/publications', 'publications', 'publications'],
  ['/products/:slug', 'product', 'product'],
  ['/products/:slug/sustainability', 'sustainability', 'sustainability'],
  ['/brands/:slug', 'brand', 'brand'],
  ['/product-checker', 'product-index'],
  ['/categories', 'categories', 'categories'],
  ['/categories/:slug', 'products', 'products'],
  ['/search', 'search', 'search'],
  ['/compare', 'compare', 'compare'],
  ['/donate', 'donate', 'donate'],

  ['/:type/:slug', 'page'],
  ['/:slug', 'landing', 'landing']
];

arr.filter((a) => a).forEach((route) => routes.add(...route));
