import { combineReducers } from 'redux';
import brands from './brands';
import prismic from './prismic';
import products from './products';
import categories from './categories';
import rankings from './rankings';
import retailers from './retailers';

export default combineReducers({
  prismic,
  brands,
  products,
  categories,
  rankings,
  retailers
});
