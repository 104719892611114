import Prismic from 'prismic-javascript';
import fetch from 'isomorphic-fetch';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PAGE_SIZE, MAXAGE_IN_DAYS } from '../constants';
import { toQS } from 'lib/query-params';
import qs from 'qs';
import * as api from 'lib/req';
import parseCsv from 'lib/csv';
import { localeMap } from 'lib/locale-cookies';
import {
  FETCH_HOME,
  FETCH_ABOUT,
  FETCH_BLOG,
  FETCH_BLOG_POST,
  FETCH_FAQ,
  FETCH_TEAM,
  FETCH_PRESS,
  FETCH_LANDING,
  FETCH_PRISMIC_WIJZERS,
  FETCH_PRISMIC_WIJZER,
  FETCH_PAGE,
  FETCH_CASE_FILES,
  FETCH_CASE_FILE,
  FETCH_IMPACTS,
  FETCH_IMPACT,
  FETCH_PUBLICATIONS,
  FETCH_PRODUCT_META,
  FETCH_CATEGORIES_META,
  FETCH_BRAND_META,
  FETCH_CONTACT,
  FETCH_COMPARE,
  FETCH_DONATE
} from 'actions/prismic';
import { FETCH_CATEGORIES, FETCH_USAGES } from 'actions/categories';
import {
  FETCH_SEARCH,
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  FETCH_ALTERNATIVES,
  FETCH_SUSTAINABILITY_REPORT
} from 'actions/products';
import { FETCH_BRAND, FETCH_BRAND_REPORT } from 'actions/brands';
import { FETCH_RANKING, FETCH_RANKINGS } from 'actions/rankings';
import { FETCH_RETAILERS } from 'actions/retailers';
import reverse from 'lib/reverse-object';
import localized from 'router/localized-types';

import { PRISMIC_API_URL } from '../config';

const STATUS_SUCCESS = 200;
const STATUS_NOT_FOUND = 404;

// Generic wrapper for all API calls
function* request(
  ACTION,
  apiFn,
  endpoint,
  params = {},
  payload = {},
  headers = {}
) {
  const { deferred, lang } = params;
  try {
    yield put({ type: `${ACTION}_REQUEST` });
    const endpointWithLocale = appendLocale(endpoint, lang);
    const data = yield call(apiFn, endpointWithLocale, payload, headers);
    // if there is no response data, we are passing back the initial payload
    const _payload = data || payload;
    yield put({ ...params, type: `${ACTION}_SUCCESS`, payload: _payload });
    if (deferred) deferred.resolve(STATUS_SUCCESS);
    return _payload;
  } catch (res) {
    const { errors } = yield res.json();
    if (deferred) deferred.resolve(STATUS_NOT_FOUND);
    yield put({ type: `${ACTION}_FAILURE`, errors });
  }
}

function* rootSaga() {
  yield all([
    takeEvery(FETCH_CATEGORIES, fetchCategories),
    takeEvery(FETCH_USAGES, fetchUsages),
    takeLatest(FETCH_PRODUCTS, fetchProducts),
    takeLatest(FETCH_SEARCH, fetchProducts),
    takeEvery(FETCH_PRODUCT, fetchProduct),
    takeEvery(FETCH_BRAND, fetchBrand),
    takeEvery(FETCH_ALTERNATIVES, fetchAlternatives),
    takeEvery(FETCH_SUSTAINABILITY_REPORT, fetchSustainabilityReport),
    takeEvery(FETCH_BRAND_REPORT, fetchBrandReport),
    takeEvery(FETCH_RANKING, fetchRanking),
    takeEvery(FETCH_RANKINGS, fetchRankings),
    takeEvery(FETCH_RETAILERS, fetchRetailers),

    takeEvery(FETCH_HOME, fetchHome),
    takeEvery(FETCH_ABOUT, fetchAbout),
    takeEvery(FETCH_BLOG, fetchBlog),
    takeEvery(FETCH_BLOG_POST, fetchBlogPost),
    takeEvery(FETCH_FAQ, fetchFaq),
    takeEvery(FETCH_TEAM, fetchTeam),
    takeEvery(FETCH_PRESS, fetchPress),
    takeEvery(FETCH_LANDING, fetchLanding),
    takeEvery(FETCH_PRISMIC_WIJZERS, fetchPrismicWijzers),
    takeEvery(FETCH_PRISMIC_WIJZER, fetchPrismicWijzer),
    takeEvery(FETCH_PAGE, fetchPage),
    takeEvery(FETCH_CASE_FILES, fetchCaseFiles),
    takeEvery(FETCH_CASE_FILE, fetchCaseFile),
    takeEvery(FETCH_IMPACTS, fetchImpacts),
    takeEvery(FETCH_IMPACT, fetchImpact),
    takeEvery(FETCH_PUBLICATIONS, fetchPublications),
    takeEvery(FETCH_CONTACT, fetchContact),
    takeEvery(FETCH_COMPARE, fetchCompare),
    takeEvery(FETCH_DONATE, fetchDonate),

    takeEvery('*', track)
  ]);
}

export default rootSaga;

// Use google analytics event tracking
// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
function track({ type, ...rest }) {
  if (type.includes('_SUCCESS') || type.includes('_REQUEST')) return;
  if (typeof window === 'undefined') return;
  if (!window.ga) return;
  // track all except _SUCCESS and _REQUEST actions
  const category = categorize(type);
  window.ga('send', 'event', category, type, '', rest);
}

function categorize(type) {
  const action = type.replace('_FAILURE', ''); // helps categorizing
  switch (action) {
    default:
      return action;
  }
}

function* getSingle(action, type, { lang = 'nl-nl', deferred, prismicRef }) {
  yield put({ type: `${action}_REQUEST` });
  try {
    const api = yield Prismic.api(PRISMIC_API_URL);
    const payload = yield api.getSingle(type, { lang, ref: prismicRef });
    if (!payload) throw new Error('Page not found');
    yield put({ type: `${action}_SUCCESS`, payload });
    if (deferred) deferred.resolve(STATUS_SUCCESS);
  } catch (e) {
    if (deferred) deferred.resolve(STATUS_NOT_FOUND);
    yield put({ type: `${action}_FAILURE`, error: e.toString() });
  }
}

function* getByUID(
  action,
  type,
  slug,
  { lang = 'nl-nl', deferred, prismicRef }
) {
  yield put({ type: `${action}_REQUEST` });
  try {
    const api = yield Prismic.api(PRISMIC_API_URL);
    const payload = yield api.getByUID(type, slug, { lang, ref: prismicRef });
    if (!payload) throw new Error('Page not found');
    yield put({ type: `${action}_SUCCESS`, payload });
    if (deferred) deferred.resolve(STATUS_SUCCESS);
  } catch (e) {
    if (deferred) deferred.resolve(STATUS_NOT_FOUND);
    yield put({ type: `${action}_FAILURE`, error: e.toString() });
  }
}

// This function is used to fetch repeatable page types and it's
// mother (*_list single page type)
function* query(
  action,
  type,
  { lang = 'nl-nl', deferred, fieldQuery, ...params }
) {
  yield put({ type: `${action}_REQUEST` });
  try {
    const api = yield Prismic.api(PRISMIC_API_URL);
    const [single, list] = yield all([
      call([api, api.getSingle], `${type}_list`, { lang }),
      call(
        [api, api.query],
        [Prismic.Predicates.any('document.type', [type]), fieldQuery],
        {
          pageSize: PAGE_SIZE,
          // For wijzers fetch the nl content and merge with en wijzer
          // listing page
          lang: type === 'wijzer' ? 'nl-nl' : lang,
          ...params
        }
      )
    ]);
    if (!list) throw new Error('Page not found');
    yield put({ type: `${action}_SUCCESS`, payload: { single, ...list } });
    if (deferred) deferred.resolve(STATUS_SUCCESS);
  } catch (e) {
    console.log(e); // eslint-disable-line
    if (deferred) deferred.resolve(STATUS_NOT_FOUND);
    yield put({ type: `${action}_FAILURE`, error: e.toString() });
  }
}

function* fetchCategories({ type, ...params }) {
  yield getSingle(FETCH_CATEGORIES_META, 'categories', { lang: params.lang });
  yield* request(type, api.get, '/categories', params);
}

function* fetchUsages({ type, ...params }) {
  const { deferred, ..._params } = params;
  yield* request(type, api.get, `/products/usages?${toQS(_params)}`, params);
}

function* fetchProducts({ type, ...params }) {
  params.confirmed_age_max = MAXAGE_IN_DAYS;
  const { deferred, ..._params } = params;
  yield* request(type, api.get, `/products?${toQS(_params)}`, params);
}

function* fetchProduct({ type, product_id, ...params }) {
  const { deferred, ..._params } = params;
  yield* request(type, api.get, `/products/${product_id}?${toQS(_params)}`, {
    product_id,
    ...params
  });
  yield getSingle(FETCH_PRODUCT_META, 'products', { lang: params.lang });
}

function* fetchBrand({ type, brand_id }) {
  yield* request(type, api.get, `/brands/${brand_id}`);
}

// deprecated
function* fetchAlternatives({ type, product_id, ...params }) {
  params.confirmed_age_max |= MAXAGE_IN_DAYS;
  const { deferred, ..._params } = params;
  yield* request(
    type,
    api.get,
    `/products/${product_id}/alternatives?${toQS(_params)}`,
    { product_id, ...params }
  );
}

function* fetchSustainabilityReport({
  type,
  product_id,
  preview_token,
  ...params
}) {
  yield* request(
    type,
    api.get,
    `/products/${product_id}/sustainability_report?${toQS({ preview_token })}`,
    {
      product_id,
      ...params
    }
  );
}

function* fetchBrandReport({
  type,
  brand_id,
  preview_token,
  confirmed_age_max,
  ...params
}) {
  confirmed_age_max = confirmed_age_max || MAXAGE_IN_DAYS;
  yield* request(
    type,
    api.get,
    `/brands/${brand_id}/profile?${toQS({ preview_token, confirmed_age_max })}`,
    {
      brand_id,
      confirmed_age_max,
      ...params
    }
  );
  yield getSingle(FETCH_BRAND_META, 'brand', {
    lang: params.lang
  });
}

function* fetchRankings({ type, per_page, ...params }) {
  yield* request(type, api.get, `/rankings?${toQS({ per_page })}`, {
    per_page,
    ...params
  });
}

function* fetchRanking({ type, ranking_id, preview_token, ...params }) {
  yield* request(
    type,
    api.get,
    `/rankings/${ranking_id}?${toQS({ preview_token })}`,
    {
      ranking_id,
      ...params
    }
  );
}

function* fetchRetailers({ type, priority, per_page, ...params }) {
  yield* request(type, api.get, `/retailers?${toQS({ priority, per_page })}`, {
    per_page,
    ...params
  });
}

function* fetchHome({ type, ...params }) {
  yield* getSingle(type, 'home', params);
}

function* fetchAbout({ type, ...params }) {
  yield* getSingle(type, 'about_main', params);
}

function* fetchFaq({ type, ...params }) {
  yield* getSingle(type, 'faq', params);
}

function* fetchTeam({ type, ...params }) {
  yield* getSingle(type, 'team', params);
}

function* fetchPress({ type, ...params }) {
  yield* getSingle(type, 'press', params);
}

function* fetchLanding({ type, slug, ...params }) {
  yield* getByUID(type, 'landing', slug, params);
}

function* fetchBlog({ type, ...params }) {
  yield query(type, 'blog', params);
}

function* fetchBlogPost({ type, slug, ...params }) {
  yield* getByUID(type, 'blog', slug, params);
}

function* fetchCaseFiles({ type, ...params }) {
  yield query(type, 'case', params);
}

function* fetchCaseFile({ type, slug, ...params }) {
  yield* getByUID(type, 'case', slug, params);
}

function* fetchImpacts({ type, ...params }) {
  yield query(type, 'impact', params);
}

function* fetchImpact({ type, slug, ...params }) {
  yield* getByUID(type, 'impact', slug, params);
}

function* fetchPublications({ type, ...params }) {
  yield query(type, 'publication', params);
}

// deprecated, for archived content only
function* fetchPrismicWijzers({ type, ...params }) {
  yield query(type, 'wijzer', params);
}

// deprecated, for archived content only
function* fetchPrismicWijzer({ type: action, slug, ...params }) {
  const { deferred, prismicRef } = params;
  // For wijzers we are always getting the dutch content as we don't publish
  // the english wijzers
  const lang = 'nl-nl';
  yield put({ type: `${action}_REQUEST` });
  try {
    const api = yield Prismic.api(PRISMIC_API_URL);
    const payload = yield api.getByUID('wijzer', slug, {
      lang,
      ref: prismicRef
    });
    const { csv } = payload.data;
    if (csv && !csv.url) {
      payload.data.csv_json = { products: [], headers: [] };
    } else {
      const res = yield fetch(csv.url).then((res) => res.text());
      payload.data.csv_json = parseCsv(res);
    }
    yield put({ type: `${action}_SUCCESS`, payload });
    if (deferred) deferred.resolve(STATUS_SUCCESS);
  } catch (e) {
    if (deferred) deferred.resolve(STATUS_NOT_FOUND);
    yield put({ type: `${action}_FAILURE`, error: e.toString() });
  }
}

function* fetchPage({ type, page_type, lang, slug, ...params }) {
  yield* getByUID(
    type,
    reverse(localized[lang])[page_type] || page_type,
    slug,
    { ...params, lang }
  );
}

function* fetchContact({ type, ...params }) {
  yield* getSingle(type, 'contact', params);
}

function* fetchCompare({ type, ...params }) {
  yield* getSingle(type, 'compare', params);
}

function* fetchDonate({ type, ...params }) {
  yield* getSingle(type, 'donate', params);
}

// append locale to the endpoint
function appendLocale(endpoint, lang) {
  const [path, qString] = endpoint.split('?');
  const query = qs.parse(qString);
  query.locale = reverse(localeMap)[lang];
  return [path, qs.stringify(query)].join('?');
}
