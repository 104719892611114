import { sizes } from 'components/Media';
import i18n from 'i18n';

export const isHome = ({ pathname }) =>
  pathname === '/' || pathname === '' || pathname === '/index-app';

export const isDevice = (size = 'phone') =>
  typeof window !== 'undefined' && window.innerWidth <= sizes[size];

export const truncate = (str, len = 100) => {
  if (str.length <= len) return str;
  return str.substring(0, len) + '...';
};

export const zIndex = (index) => ({
  zIndex: 100 + index,
  position: 'relative'
});

export function themeFullName(theme) {
  return i18n.t(`THEME_${theme.name.toUpperCase()}`);
}

export function themeShortName(theme) {
  return i18n.t(`THEME_SHORT_${theme.name.toUpperCase()}`);
}

// @see https://stackoverflow.com/a/3765601/2866660
export function niceList(array, join = ', ', final = ` ${i18n.t('AND')} `) {
  return array.reduce(function (pv, cv, i, a) {
    return pv + (i === a.length - 1 ? final : join) + cv;
  });
}

// same as the previous, but returns an array, so elements can be objects when working with React
export function niceListArray(
  array,
  join = ', ',
  final = ` ${i18n.t('AND')} `
) {
  return array.slice(1).reduce(
    function (pv, cv, i, a) {
      return [...pv, i === a.length - 1 ? final : join, cv];
    },
    [array[0]]
  );
}

export const stringCompare = (a, b) =>
  Boolean(a && b && a.toString() === b.toString());

export const arrayToObject = (key, arr) => {
  return arr.reduce((res, item) => {
    res[item[key]] = item;
    return res;
  }, {});
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#Getting_a_random_integer_between_two_values_inclusive
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
}
