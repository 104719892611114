import Prismic from 'prismic-javascript';
import getCookies from 'next-cookies';

// To enable prismic preview
// https://prismic.io/docs/javascript/beyond-the-api/in-website-preview#17_1-4.-use-the-correct-reference

export default function getPreviewRef(req) {
  const cookies = getCookies({ req });
  return cookies[Prismic.previewCookie];
}
