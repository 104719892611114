module.exports = {
  LANG_EN: 'English',
  LANG_SHORT_EN: 'EN',
  LANG_NL: 'Nederlands',
  LANG_SHORT_NL: 'NL',
  AND: 'and',
  ABOUT: 'About',
  VISION: 'Vision',
  VACANCIES: 'Vacancies',
  TEAM: 'Team',
  BLOG: 'Blog',
  COMMUNITY: 'Community',
  DEVELOPERS: 'Developers',
  PARTNERS: 'Partners',
  BOARD: 'Board',
  BOARD_ADVISORS: 'Board of advisors',
  PRESS: 'Press',
  IN_THE_MEDIA: 'In the media',
  HISTORY: 'History',
  DONATE: 'Donate',
  JOIN: 'Join us',
  BROWSE: 'Browse all',
  READ_MORE: 'Read more',
  NEWS: 'News',
  CASE_FILES: 'Case files',
  BACK_TO_LIST: 'Back to list',
  ERROR_404: 'We were not able to find the page you are looking for.',
  ERROR_GENERIC: 'Oops, something went wrong!',
  CONTACT: 'Contact',
  CATEGORIES: 'Categories',
  SUB_CATEGORIES: 'Subcategories',
  NUTRIENTS: 'Nutritional facts',
  PRODUCER: 'Producer',
  PRODUCERS: 'Producers',
  SUPERMARKET: 'Supermarket',
  INGREDIENTS: 'Ingredients',
  REFRIGERATION_TYPE: 'Refrigeration',
  PACKAGING: 'Packaging',
  CERTIFICATE: 'Certificate',
  CERTIFICATES: 'Certifications',
  ALTERNATIVES: 'Alternatives',
  BACK: 'Back',

  HEALTH_A: 'Yes',
  HEALTH_B1: 'Fair',
  HEALTH_B2: 'Poor',
  HEALTH_C1: 'Very poor',
  HEALTH_C2: 'Least',

  HEALTHINESS_SCORE_A: 'fits in a <b>healthy diet</b>',
  HEALTHINESS_SCORE_B1: 'can be a <b>daily exception</b> to an otherwise healthy diet',
  HEALTHINESS_SCORE_B2: 'can be a <b>daily exception</b> to an otherwise healthy diet',
  HEALTHINESS_SCORE_C1: 'can be a <b>weekly exception</b> to an otherwise healthy diet',
  HEALTHINESS_SCORE_C2: 'can be a <b>weekly exception</b> to an otherwise healthy diet',

  HEALTHINESS_OF: 'This product %{score}.',

  THEME_ENVIRONMENT: 'Environment',
  THEME_SOCIAL: 'Human rights',
  THEME_ANIMALS: 'Animal welfare',
  THEME_GENERAL: 'General',
  THEME_WARNING_UNFROZEN: 'Attention',

  THEME_SHORT_ENVIRONMENT: 'Environment',
  THEME_SHORT_SOCIAL: 'Human rights',
  THEME_SHORT_ANIMALS: 'Animal welfare',
  THEME_SHORT_GENERAL: 'General',
  THEME_SHORT_WARNING_UNFROZEN: 'Attention',

  HEALTHY: 'Healthy',
  HEALTH: 'Healthy',
  SUSTAINABILITY: 'Sustainable',
  SUSTAINABLE: 'Sustainable',

  EMAIL_ADDRESS: 'Email address',
  SUBSCRIBE: 'Subscribe',
  THANK_YOU: 'Thank you',
  SHARE: 'Share',

  PRODUCT_CHECKER_MISSION: 'Want to eat sustainable and healthy?',
  PRODUCT_CHECKER_CAPTION: 'We help you to compare and choose',
  SEARCH: 'Search a brand or product',
  NO_SEARCH_RESULTS: 'No search results',
  SHOW_ALL_N_RESULTS: {
    one: 'Show one result',
    other: 'Show all %{count} results'
  },
  SEARCH_RESULTS_FOR: 'Search results for',
  BEST_MATCH: 'Best match',
  MOST_SUSTAINABLE: 'Top certificates', // temporary solution until bdk is released
  LEAST_SUSTAINABLE: 'Least sustainable',
  MOST_HEALTHY: 'Most healthy',
  LEAST_HEALTHY: 'Least healthy',
  CLEAR: 'Clear',
  SORT_ON: 'Sort on',

  APP_INDEX_TAGLINE:
    'Scan or search product to see how healthy or sustainable it is.',

  BROWSE_CATEGORIES: 'Browse categories',
  WIJZER: 'Product rankings',
  CLARIFICATION: 'Clarification',
  SHOW: 'Show',
  SHOW_ALL: 'Show all',
  BEST: 'Highest',
  WORST: 'Lowest',
  WIJZER_SHARE_TITLE: 'Are you a business or press, and want to know more?',
  EXPAND: 'Show all',
  SHOW_LESS: 'Collapse',
  COLLAPSE: 'Collapse',
  FAQS: 'Frequently asked questions',
  FOLLOW_US: 'Follow us',
  IMPACT: 'Impact',
  PAGE_NOT_FOUND: 'Page not found',
  OTHER_CERTIFICATES: 'Other certifications and marks',
  MSG_NO_SCORE_HEALTH: 'It was not possible to calculate a health score for this product.',
  TOP_CERTIFICATES: 'Top-certificates',
  SOURCE: 'Source',
  CTA_SUBSCRIBE_TITLE: 'Subscribe to our newsletter',
  CTA_LINK_TITLE: 'Subscribe',
  DIRECTLY_TO_WIJZER: 'To ranking',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  BRAND: 'Brand',
  NEW: 'New',
  FILTER_ON: 'Filter on',
  FILTER_SHOW: 'Show',
  ALL: 'All',
  SUSTAINABLE_ALTERNATIVES: 'More sustainable alternatives',

  PAGE: 'Page',
  RENEWED: 'Renewed',
  WIJZER_ISSUES: 'These issues are relevant',
  REACTIONS_FROM_BRANDS: 'Reactions from brands',
  REACTIONS_PRODUCERS: 'Reactions from producers',
  WIJZER_GOAL:
    'Questionmark researches a product group on health and sustainability',
  WIJZER_RESPONSES_1: 'These',
  WIJZER_RESPONSES_2: 'producers have responded to the ranking',

  NOTICE_PILOT: 'This page and all information are part of a pilot project.',
  NOTICE_PREVIEW: 'This page and all information are unpublished and only visible with the confidential link.',
  NOTICE_CONTACT: 'For more information and questions, please mail',
  HOW_SUSTAINABLE_IS: 'How sustainable is',
  HOW_SUSTAINABLE_IS_SUBJECT: 'How sustainable is %{subject}:',
  BACK_TO_PRODUCT: 'Back to product',
  THIS_PRODUCT: 'this product',
  BRAND_IS_TRANSPARENT_QM: 'This brand is transparent through Questionmark',
  BRAND_X_IS_TRANSPARENT_QM: '%{brand} is transparent through Questionmark',
  BRAND_X_IS_TRANSPARENT_ABOUT: '%{brand} writes about',
  BRAND_TRANSPARENT_ABOUT_A: '%{brand} is transparent about their measures for',
  BRAND_TRANSPARENT_ABOUT_B:
    ', based on internationally recognized questionnaires.',
  BRAND_TRANSPARENT_READ_MORE: 'Read what they are doing',
  NO_STATEMENTS: 'There is no information about efforts in this step.',
  NO_RELEVANT_STATEMENTS:
    'The main focus areas for this product are not in this step.',
  MORE_INFO_CASE_FILE: 'For more information see the',
  CASE_FILE_OF: 'case file of %{category}',
  THE_CHAIN: 'Suppy-chain of %{brand}',
  MEASURES_OF_BRAND: 'Measures taken by %{brand}',
  CHAIN_HELP:
    'Before the product is in your hands, it has had a long journey. Here you see the most important steps of this journey through the supply chain. Each step in the chain shows what %{brand} indicates to do to mitigate issues in that step. You can see measures required by a top-certificate, by selecting the appropriate filter. You can also see what a brand does extra, by filtering on \'own measures\'.',
  CHAIN_WIDE_MEASURES: 'Chain-wide measures',
  SELECT_ALL_MEASURES: 'Clear filters to see measures in this step',
  BRAND_QUOTE_INTRO: '%{brand} on their sustainability:',
  AREAS_OF_CONCERN_GENERAL: 'General areas of concern',
  AREAS_OF_CONCERN_OF: 'Areas of concern of %{category}',
  FILTER_THEME_DESC:
    'Measures in the area of %{theme}, both certifications requirements and own measures',
  FILTER_CERT_DESC: 'Minimum requirements set by certification %{name}',
  OWN_MEASURES: 'Own measures',
  OWN_MEASURES_DESC: 'Measures taken by %{brand} in addition to or instead of certification requirements',
  CERTIFICATE_MEASURES: 'Certificate %{certificate}',
  CERTIFICATE_MEASURES_MULTIPLE: 'Certification measures',
  READ_MORE_ABOUT: 'Read more about',
  MORE_ABOUT_BRAND: 'More about %{brand}',
  READ_MORE_ABOUT_BRAND_DESC:
    'Read more about the health and sustainability ambitions of %{brand}',
  THEMES: 'Themes',
  CERTIFICATES_AND_MEASURES: 'Certifications and own measures',

  BRAND_PAGE_INTRO:
    'On this page you can read more about the ambitions, challenges and efforts on sustainability and health of %{brand}. The information on this page is provided by %{brand}.',
  VIEW_BRAND_PRODUCTS: {
    one: 'View the one product of %{brand} as known to Questionmark',
    other: 'View the %{count} products of %{brand} as known to Questionmark'
  },
  SUSTAINABILITY_POLICIES: 'Sustainability efforts',
  SUSTAINABILITY_POLICIES_INTRO: 'These are the most important current efforts of %{brand} on sustainability:',
  SUSTAINABILITY_AMBITIONS: 'Sustainability ambitions',
  SUSTAINABILITY_AMBITIONS_INTRO: 'In the coming years, %{brand} is working in these areas on sustainability:',
  HEALTH_POLICIES: 'Health efforts',
  HEALTH_POLICIES_INTRO: 'These are the most important current efforts of %{brand} on health:',
  HEALTH_AMBITIONS: 'Health ambitions',
  HEALTH_AMBITIONS_INTRO: 'In the coming years, %{brand} is working in these areas on health:',
  HEALTH_SCORES: 'Health scores',
  TOP_CERTIFICATE_PRODUCT_COUNT: {
    one: '1 product with certificate %{certificate}',
    other: '%{count} products with certificate %{certificate}'
  },
  NO_TOP_CERTIFICATES_ALL_PRODUCTS: '%{brand} does not use <a href="/dossier/topkeurmerken-op-een-rij">top-certificates</a>.',
  NO_TOP_CERTIFICATES_PRODUCT_COUNT: {
    one: 'For one product %{brand} does not use a <a href="/dossier/topkeurmerken-op-een-rij">top-certificate</a>.',
    other: 'For %{count} products %{brand} does not use a <a href="/dossier/topkeurmerken-op-een-rij">top-certificate</a>.'
  },

  PRODUCTS_IN_SCHIJF: {
    one: 'One of %{total} products is healthy according to the <a href="/schijf-van-vijf">schijf van vijf</a>',
    other: '%{count} of %{total} products are healthy according to the <a href="/schijf-van-vijf">schijf van vijf</a>'
  },
  PRODUCTS_IN_SCHIJF_ALL: {
    one: 'The product is healthy according to the <a href="/schijf-van-vijf">schijf van vijf</a>',
    other: 'All products are healthy according to the <a href="/schijf-van-vijf">schijf van vijf</a>'
  },
  PRODUCTS_IN_SCHIJF_ALL_X: {
    one: 'One product is healthy according to the <a href="/schijf-van-vijf">schijf van vijf</a>',
    other: 'All %{count} products are healthy according to the <a href="/schijf-van-vijf">schijf van vijf</a>'
  },
  PRODUCTS_OUTSIDE_SCHIJF: {
    one: 'One of %{total} products falls in the category %{score} healthy',
    other: '%{count} of %{total} products fall in the category %{score} healthy'
  },
  PRODUCTS_OUTSIDE_SCHIJF_ALL: {
    one: 'The product falls in the category %{score} healthy',
    other: 'All products fall in the category %{score} healthy'
  },
  PRODUCTS_OUTSIDE_SCHIJF_ALL_X: {
    one: 'One product falls in the category %{score} healthy',
    other: 'All %{count} products fall in the category %{score} healthy'
  },
  PRODUCTS_WITHOUT_SCORE: {
    one: '(and one product without a rating)',
    other: '(and %{count} products without a rating)'
  },

  INITIATIVES: '%{brand} participates in…',
  INITIATIVE_READ_MORE: 'Read more about the initiative',
  SEND_AN_EMAIL: 'Send an email',
  WEBSITE: 'Website',
  CSR_REPORT: 'CSR report',
  INFO_PROVIDED_ON: 'Information provided on %{date}',
  INFO_GATHERED_ON: 'Based on public information from %{date}',
  VIEW_MEASURES: 'See the measures',
  PRODUCT: 'Product',
  HEALTH: 'Health',
  SUSTAINABILITY: 'Sustainability',
  SUSTAINABILITY_EXPLANATION: 'The list below shows how much openness a brand gives for each topic. Where possible we\'ve also included the efforts and results.',
  IMPORTANT_FOR_X: 'Important for',
  MORE_ABOUT_HEALTH: 'More about the health of this product',
  MORE_ABOUT_SUSTAINABILITY: 'More about the sustainability of this product',
  MORE_ABOUT_RANKING: 'More about the ranking',
  FRONT_RUNNERS: 'Frontrunners',
  MIDDLE_RUNNERS: 'Mid range',
  BACK_RUNNERS: 'Laggards',
  FRONT_RUNNER: 'Frontrunner',
  MIDDLE_RUNNER: 'Mid range',
  BACK_RUNNER: 'Laggard',
  VIEW_MIDDLE_RUNNERS: 'View mid range',
  CLOSE_MIDDLE_RUNNERS: 'Close mid range',
  WHAT_DO_WE_WEIGH: 'What is included?',
  BEST_IN_SUSTAINABILITY: 'Best score sustainability',
  BEST_IN_HEALTH: 'Best score health',
  NONE: 'None',
  NUTRIENT_SALT: 'Salt',
  NUTRIENT_SUGAR: 'Sugar',
  NUTRIENT_FAT_SATURATED: 'Saturated fat',
  NUTRIENT_ENERGY: 'Energy',
  NUTRIENT_FIBER: 'Fiber',
  NUTRIENT_CARBOHYDRATES: 'Carbohydrates',
  NUTRIENT_CALCIUM: 'Calcium',
  NUTRIENT_PROTEIN: 'Protein',
  NUTRIENT_IRON: 'Iron',
  NUTRIENT_ENERGY_THERMAL: 'Energy (kcal)',
  NUTRIENT_B12: 'Vitamin B12',

  COMPARE_PRODUCTS: 'Compare products',
  COMPARE: 'Compare',
  SELECT_PRODUCTS_TO_COMPARE: 'Select products to compare',
  READ_LESS: 'Read less',
  CHOOSE_A_PRODUCT: 'Choose a product',
  CHOOSE_ANOTHER_PRODUCT: 'Choose another product',
  CANCEL: 'Cancel',
  SELECT_ONE_MORE: 'Select one more product',
  CHANGE_PRODUCT: 'Change product',
  COMPARE_LIMIT_PRODUCTS: 'You can only compare two products',
  COPY: 'Copy',
  COPIED: 'Copied',
  COMPARE_NOW: 'Compare now',
  ADD_PRODUCT: 'Add product',
  PRODUCT_RANKING_INFO: '%{name} stands at place %{position} in the %{ranking_name}.',
  COMPARE_PRODUCT_ON: 'Compare %{name} with another product on health and sustainability.',
  TRANSPARENCY_INFO: 'Transparent about %{count} measures',
  SEE_THE_RANKING: 'See the ranking',
  PRODUCT_RANKING: 'Product ranking',
  SUBSCRIBE_RANKING_DESCRIPTION: 'Subscribe',
  WANT_TO_KNOW_MORE: 'Want to know more',
  CONTACT_US: 'Contact us',
  BEST_SCORE: 'Best score',
  ALL_RETAILERS: 'All supermarkets',

  SLOGAN_SUPERLIST: 'How do supermarkets shape our food system?',
  SLOGAN_RANKING: 'Compare cheese, tomato soup and %{count} other product groups.',
  SLOGAN_PRODUCT_CHECKER: 'Look up a product and check how healthy and sustainable it is.',

  COUNT_PRODUCTS: {
    one: 'one product',
    other: '%{display} products'
  },
  COUNT_RANKINGS: {
    one: 'one ranking',
    other: '%{display} rankings',
  },
  COUNT_RETAILERS: {
    one: 'one retailer chain',
    other: '%{display} retailer chains'
  },
  COUNT_VISITORS: {
    one: 'one visitor',
    other: '%{display} visitors'
  },
  COUNT_SCANNED: {
    one: 'one product looked up',
    other: '%{display} products looked up'
  },
  PER_MONTH: 'per month',

  QUESTIONMARK: 'Questionmark',
  SECTION_SUPERLIST: 'Superlist',
  SECTION_RANKING: {
    one: 'Product ranking',
    other: 'Product rankings'
  },
  SECTION_PRODUCT_CHECKER: 'Product checker',

  EXPECTED: 'Expected',
  PUBLICATIONS: 'Publications',
  AND_OTHER_PUBLICATIONS: {
    one: '+ one other publication',
    other: '+ %{count} other publications'
  },
  DOWNLOAD_PUBLICATION: 'Download publication',

  DONATION_ERROR: 'Sorry, something unexpected happened while donating.',
  RECEIVE_NEWSLETTER: 'Receive our newsletter',
  DONATION_AMOUNT: 'Amount',
  DONATE: 'Donate',
  SIDE_NAV_TITLE: 'Related topics',
  DISCOVER: 'Discover',
};
