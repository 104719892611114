import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { isDevBuild } from './config';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (isDevBuild) {
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares)
  );

  // NOTE: you must attach `sagaTask` to the store.
  // NOTE: next-redux-saga will use `runSagaTask` and init new example of `rootSaga` on every page in sync mode
  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };
  store.runSagaTask();
  return store;
}
